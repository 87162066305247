import React, { Component } from "react";
import emailjs from 'emailjs-com';

class ContactTwo extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: ''
        };
    }

    handleSubmit(e) {
        const templateParams = {
            from_name: this.state.rnName,
            from_email: this.state.rnEmail,
            subject: this.state.rnSubject,
            message_html: this.state.rnMessage
        };
        e.preventDefault();
    
        emailjs.send('smtp_server', 'template_Ize26yvJ', templateParams, 'user_95pUv7sHOy5YMRYvScXxP')
          .then((result) => {
              console.log(result.text);
              this.props.showToastr();
          }, (error) => {
              console.log(error.text);
          });
      }

    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-center">
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Bize Ulaşın</h2>
                            </div>
                            <div className="form-wrapper">
                                <form onSubmit={this.handleSubmit.bind(this)} method="post" encType="text/plain">
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                            placeholder="İsminiz *"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                            placeholder="Email *"
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e)=>{this.setState({rnSubject: e.target.value});}}
                                            placeholder="Konu"
                                        />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                            placeholder="Mesaj"
                                        />
                                    </label>
                                    <button className="rn-button-style--2 btn-solid" type="submit" value="send" name="submit" id="mc-embedded-subscribe">Gönder</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;