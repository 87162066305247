// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

// Common Layout
// import Layout from "./component/common/App";
import HttpsRedirect from "react-https-redirect";

// Home layout
import InteriorLanding from "./home/Interior";

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";

// Blocks Layout

import Team from "./blocks/Team";
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";
import MetaTags from "react-meta-tags";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <MetaTags>
          <title>Minimal Contacts</title>
          <h1>Minimal Contacts</h1>
          <meta
            name="description"
            content="Minimal Contacts Duvar kaplama sektöründe 15 yıllık deneyime sahip olan ve 2003 yılında Ankara'da kurulan Minimal Contracts, sektörlerinde dünya lideri olan J.Josephson ve LSI Wallcovering markalarının Ankara temsilcisidir."
          />
          <meta
            name="keywords"
            content="myminimal.com, myminimal, Minimal Contacts, Minimal Contacts Ankara, Minimal Contacts Interior Architect, minimal contracts, minimal, Interior Architect, iç mimarlık, iç mimar ankara, iç mimar, antibakteriyel, kumaş, kumas, Arlin, dekorasyon, minimal, döşeme kumaş, doseme kumas, döşemelik, dosemelik, duvar kağıdı,"
          ></meta>
          <meta property="og:title" content="Minimal Contacts" />
          <meta name="author" content="Minimal Contacts"></meta>
          <meta name="publisher" content="VS Code" />
          <meta name="robots" content="noindex" />
        </MetaTags>
        <HttpsRedirect>
          <Switch>
            <Route exact path={`/`} component={InteriorLanding} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              component={Contact}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/404`}
              component={error404}
            />
            <Route component={error404} />
          </Switch>
        </HttpsRedirect>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
