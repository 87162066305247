import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const PortfolioList = [
    {
        image: 'image-1',
        title: 'Duvar Kaplamaları'
    },
    {
        image: 'image-2',
        title: 'Döşemelik Tekstil'
    },
    {
        image: 'image-3',
        title: 'Perdelik Tekstil'
    }
]
class Testimonial extends Component {

    render() {
        const list = PortfolioList.slice(0, this.props.item);
        const { column } = this.props;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Amerika’da yüksek teknoloji ile uluslararası standartlarda üretilen, sayısız renk ve doku seçeneğine sahip dekoratif ve kaliteli ürünlerdir. Tekstil taban (osnaburg, örgülü taban, pislikleri örtme özelliği vardır) polyester ipliğidir. Otel, işyeri, hastane, laboratuar, konut ve restoranlar gibi yoğun kullanımlı mekanların yanı sıra ıslak hacimli mekanlarda bile, buhardan etkilenmeyen özelliği sayesinde, seramik yerine kullanılabilir. 1.5 mm2’ye kadar duvardaki çatlaklıkları ve bozuklukları kapatır. Bakteri yok edici ve küf önleyici maddeler barındıran özelliği sayesinde rutubet ve küf oluşumunu engeller.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Tekstil Tabanlı Vinil Duvar Kaplaması </span> </h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Döşemelik tekstil ürünlerimizi özgün tasarımlarıyla öne çıkan Designtex firmasından ve deri, suni deri, kürk ve suni kürk ürünleriyle öne çıkan WinterCreation firmalarından tedarik etmekteyiz. Ayrıca Bursa’da bulunan fabrikımızda, istenilen renk, desen, metraj ve spesifikasyonlara göre özel üretim yaptırma olanağını da sahibiz. Temsilcimisi olduğumuz firmaların geniş ürün yelpazesi ve Türkiye’de özel üretim imkanına sahip olmamız, müşterilerimizin dekoratif döşemelik tekstil ürünü taleplerini kısa sürede karşılayabilmemize imkan sağlamaktadır.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Döşemelik Tekstil </span></h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Perdelik tekstil ürünlerimizi tıpkı döşemelik tekstil ürünlerimizde olduğu gibi Bursa’da bulunan üretim fabrikamızda istenilen metraj, renk, baskı, doku ve spesifikasyonlarda ürettirebiliyoruz. Sadece perdelik tekstil konusunda değil, perde sistemleri üretiminde ve uygulamasında da yardımcı oluyoruz.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Perdelik Tekstil </span> </h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabList className="testimonial-thumb-wrapper">
                                <Tab hidden></Tab>
                                {list.map((value, index) => (
                                    <Tab  className={`${column}`} key={index}>
                                        <div>
                                            <div className="Tilt-inner">
                                                <div className="portfolio thumb">
                                                    <div className="thumbnail-inner">
                                                        <div className={`thumbnail ${value.image}`}></div>
                                                        <div className={`bg-blr-image ${value.image}`}></div>
                                                    </div>
                                                    <div className="content">
                                                        <div className="inner">
                                                            <h4><a href="/">{value.title}</a></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>

                                ))}
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;