import React, { Component, Fragment } from "react";
import Scrollspy from 'react-scrollspy';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import FooterTwo from "../component/footer/FooterTwo";
import PortfolioMasonry from "../elements/portfolio/PortfolioMasonry";
import Helmet from "../component/common/Helmet";
import Slider from "react-slick";
import Testimonial from "../elements/Testimonial";
import { slickDot, portfolioSlick2 } from "../page-demo/script";


const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'MINIMAL',
        description: "Duvar kaplama sektöründe 15 yıllık deneyime sahip olan ve 2003 yılında Ankara'da kurulan Minimal Contracts, sektörlerinde dünya lideri olan J.Josephson ve LSI Wallcovering markalarının Ankara temsilcisidir.",
        buttonText: 'İLETİŞİM',
        buttonLink: '/contact'
    }
]

const PortfolioList2 = [
    {
        imgSrc: '/assets/images/logo/onetower.png',
        title: ' One Tower'
    },
    {
        imgSrc: '/assets/images/logo/cepaevleri.png',
        title: ' Cepa Evleri'
    },
    {
        imgSrc: '/assets/images/logo/parktepe.png',
        title: ' Park Tepe'
    },
    {
        imgSrc: '/assets/images/logo/uptown.png',
        title: ' Up Town'
    },
    {
        imgSrc: '/assets/images/logo/aden.png',
        title: ' Aden'
    },
    {
        imgSrc: '/assets/images/logo/bayterrace.png',
        title: ' Bayterrace'
    },
    {
        imgSrc: '/assets/images/logo/park-vadi.png',
        title: ' Park Vadi'
    },
    {
        imgSrc: '/assets/images/logo/lotus-beytepe.png',
        title: ' Lotus Beytepe'
    },
    {
        imgSrc: '/assets/images/logo/lotus-cayyolu.png',
        title: ' Lotus Çayyolu'
    }
    ,
    {
        imgSrc: '/assets/images/logo/vestalife.png',
        title: ' Vesta Life'
    },
    {
        imgSrc: '/assets/images/logo/vestasuite.png',
        title: ' Vesta Suite'
    }
]

const PortfolioList1 = [
    {
        imgSrc: '/assets/images/logo/hiltonankara.png',
        title: ' Hilton Garden Inn Ankara'
    },
    {
        imgSrc: '/assets/images/logo/hilton-izmir.png',
        title: ' Hilton Garden Inn İzmir'
    },
    {
        imgSrc: '/assets/images/logo/dedeman.png',
        title: ' Dedeman Hotel Erbil'
    },
    {
        imgSrc: '/assets/images/logo/scinn.png',
        title: ' SC Inn Hotels Ankara'
    },
    {
        imgSrc: '/assets/images/logo/ankalife.png',
        title: ' Anka Life'
    }
]


const ServiceListOne = [
    {
        description: '5 yıl ışığa dayanıklılık garantisi, dış yüzey vinly ve iç yüzey tekstil esaslıdır'
    },
    {
        description: 'Ortam nemine karşı %100 su geçirmez vinly kaplama'
    },
    {
        description: 'kolay temizlenebilir , kokuya dayanıklı, antimikrobiyal'
    },
    {
        description: 'Ürünlerde kullanılan vinly, su bazlı boyalarda kullanılan poliamid ile aynı özelliklere sahiptir.'
    },
    {
        description: 'Hiçbir ürün kurşun, cıva, kadmiyum, krom gibi sağlıksız metalleri içermez'
    },
    {
        description: "1,5 mm'ye kadar alçı çatlaklarını kapsar"
    }
]

class InteriorLanding extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open');
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader() { }
    render() {
        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 50) {
                document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        return (
            <Fragment>
                <Helmet pageTitle="Minimal Contracts" />

                {/* Start Header Area  */}
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/minimal-logo.png" alt="Logo Images" />
                                    <img className="logo-2" src="/assets/images/logo/minimal-logo.png" alt="Logo Images" />
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home', 'products', 'projects', 'about']} currentClassName="is-current" offset={-200}>
                                    <li onClick={this.CLoseMenuTrigger}><a href="#home">Ana Sayfa</a></li>
                                    <li onClick={this.CLoseMenuTrigger}><a href="#products">Ürünler</a></li>
                                    <li onClick={this.CLoseMenuTrigger}><a href="#projects">Referanslar</a></li>
                                    <li onClick={this.CLoseMenuTrigger}><a href="#about">Hakkında</a></li>
                                </Scrollspy>
                            </nav>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-activation slider-creative-agency" id="home">
                    <div className="bg_image bg_image-main" data-black-overlay="4">
                        {SlideList.map((value, index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {<img src="/assets/images/logo/minimal-big-logo.png" alt="Logo Images" />}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Testimonial Area */}
                {/* <div className="rn-testimonial-area bg_color--5 ptb--120" id="testimonial">
                    <div className="container">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30">
                                        <h2 className="title">Ürünlerimiz</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Testimonial item="3" column="col-lg-4 col-md-6 col-sm-6 col-12 portfolio-tilthover" />
                    </div>
                </div> */}
                {/* End Testimonial Area */}

                {/* Start products Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--120" id="products">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2 className="title">Ürünlerimiz</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <PortfolioMasonry item="3" column="col-lg-4 col-md-6 col-sm-6 col-12 portfolio-tilthover" />
                        </div>
                    </div>
                </div>
                {/* End products Area  */}

                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
                    <div className="container">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30">
                                        <h2 className="title">Ürün Özellikleri</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper mt--30">
                            {ServiceListOne.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="service service__style--1">
                                        <div className="content">
                                            <h4 className="title">{val.title}</h4>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1" id="projects">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title text-left service-style--3 mb--30">
                                    <h2 className="title">Ev Projeleri</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper portfolio-sacousel-inner mb--55">
                        <div className="portfolio-slick-activation mt--30 mt_sm--30">
                            <Slider {...portfolioSlick2}>
                                {PortfolioList2.map((value, index) => (
                                    <div className="portfolio portfolio-interior-design" key={index}>
                                        <div className="thumbnail-inner">
                                            <img className="thumbnail" src={value.imgSrc} alt="Logo Images" />
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <h4>{value.title}</h4>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1" id="projects">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="section-title text-left service-style--3 mb--30">
                                    <h2 className="title">Otel & Hastane Projeleri</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper portfolio-sacousel-inner mb--55">
                        <div className="portfolio-slick-activation mt--30 mt_sm--30">
                            <Slider {...portfolioSlick2}>
                                {PortfolioList1.map((value, index) => (
                                    <div className="portfolio portfolio-interior-design" key={index}>
                                        <div className="thumbnail-inner">
                                            <img className="thumbnail" src={value.imgSrc} alt="Logo Images" />
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <h4>{value.title}</h4>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top" id="about">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}

export default InteriorLanding;