import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import ContactTwo from "../elements/contact/ContactTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import mapboxgl from 'mapbox-gl';
import FooterTwo from "../component/footer/FooterTwo";

mapboxgl.accessToken = "pk.eyJ1Ijoib3phbjE5MTAiLCJhIjoiY2s5M3d6dWZ3MDBnZjNmb3ViNDVxZGtncCJ9.AYqb2eKvJxRXds1r9Xf_Mg";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component {

    mapRef = React.createRef();

    state = {
        lat: 39.896999,
        lng: 32.865707,
        zoom: 17,
        geojson: {
            type: 'FeatureCollection',
            features: [{
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [39.896999, 32.865707]
                },
                properties: {
                    title: 'Minimalad',
                    description: 'Minimal Contracts'
                }
            }]
        }
    };

    static defaultProps = {
        center: {
            lat: 39.896999,
            lng: 32.865707
        },
        zoom: 17
    };

    showToastr() {
        console.log("toastr");
    }

    componentDidMount() {
        var gson = this.state.geojson;
        var map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom
        });

        map.on('move', () => {
            this.setState({
                lng: map.getCenter().lng.toFixed(4),
                lat: map.getCenter().lat.toFixed(4),
                zoom: map.getZoom().toFixed(2)
            });
        });

        map.on('load', function () {
            map.loadImage(
                '/assets/images/logo/marker.png',
                function (error, image) {
                    if (error) throw error;
                    map.addImage('marker', image);
                    map.addSource('point', {
                        'type': 'geojson',
                        'data': {
                            'type': 'FeatureCollection',
                            'features': [
                                {
                                    'type': 'Feature',
                                    'properties': {
                                        'description':
                                        '<strong>Make it Mount Pleasant</strong><p><a href="http://www.mtpleasantdc.com/makeitmtpleasant" target="_blank" title="Opens in a new window">Make it Mount Pleasant</a> is a handmade and vintage market and afternoon of live entertainment and kids activities. 12:00-6:00 p.m.</p>',
                                        'icon': 'theatre'
                                        },
                                    'geometry': {
                                        'type': 'Point',
                                        'coordinates': [32.865707, 39.897100]
                                    }
                                }
                            ]
                        }
                    });
                    map.addLayer({
                        'id': 'points',
                        'type': 'symbol',
                        'source': 'point',
                        'layout': {
                            'icon-image': 'marker',
                            'icon-size': 0.1,
                            'icon-allow-overlap': true,
                        }
                    });
                }
                
            );
        });

        document.getElementsByClassName("sidebarStyle")[0].style.visibility = 'hidden';
    }

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="minimal-big-logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area" data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">İletişim</h2>
                                    <p>m i n i m a l </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">

                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Telefon</h4>
                                        <p><a href="tel:+90 533 603 23 00">+90 533 603 23 00</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email</h4>
                                        <p><a href="mailto:info@myminimal.com">info@myminimal.com</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Adres</h4>
                                        <p><a href="#location">Borazan Sk. No:13/A Gaziosmanpaşa Mh.<br /> Çankaya / ANKARA </a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo showToastr={this.showToastr} />
                </div>
                {/* End Contact Page Area  */}

                {/* Start Contact Map  */}
                <div className="rn-contact-map-area position-relative" id="location">
                    <div style={{ height: '400px', width: '100%' }}>
                        <div className='sidebarStyle'>
                            <div>Longitude: {this.state.lng} | Latitude: {this.state.lat} | Zoom: {this.state.zoom}</div>
                        </div>
                        <div id='map' ref={el => this.mapContainer = el} className='mapContainer' />
                    </div>
                </div>
                {/* End Contact Map  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default Contact